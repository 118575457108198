<script>
export let sad;

</script>
<div class="header">

 <!-- <img src="{sad.logo}" alt="photo"/>
-->
  <h1>
    {sad.name} 
  </h1>
  <h2>
    {sad.full_name} 
  </h2>
  <div class="hidden-number">{sad.nomer} </div>
</div>