<script>
export let sad;
import ContactItem from '../components/ContactItem.svelte';
console.log(sad.id)
</script>

{#if sad.id == 918}
<div class="hd10">
{sad.full_name} 
</div>
{:else}
    <ContactItem src="baseline-person-24px.svg" alt="Руководитель">
      {sad.zaved} <!--Заменить-->
    </ContactItem>
    
    <ContactItem src="baseline-home-24px.svg" alt="адрес">
      {sad.adres} <!--Заменить-->
    </ContactItem>
    <ContactItem src="baseline-call-24px.svg" alt="телефон">
      <a href="tel:{sad.phone}">{sad.phone}</a> <!--Заменить-->
    </ContactItem>
    <ContactItem src="baseline-mail_outline-24px.svg" alt="e-mail">
      <a href="mailto:{sad.email}">{sad.email}</a> <!--Заменить-->
    </ContactItem>
    {/if}