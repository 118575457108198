<script>

</script>

<div class="hor-menu-item">
  <ul>
    <a rel=prefetch href="/album">
      <li>
        Фотогалерея
      </li>
    </a>
    <a rel=prefetch href="/comments">
      <li>
        Отзывы
      </li>
    </a>
    <a href="/reception">
      <li>
        Интернет-приёмная
      </li>
    </a>
  </ul>
</div>