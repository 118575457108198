	<script>
    import { getContext } from "svelte";
    import Pos from './Pos.svelte'
    export let str;
    let { start } = getContext("sad");

	
</script>

<div class="str">
  <h3>
    {str.str_name} <!--Заменить-->
  </h3>
 <div class="str-content">
  {#if str.id==start}
  <Pos />
  {/if}

  {@html str.redactor.replace(/http:\/\/mdoy.pro/g, 'https:\/\/mdoy.pro')}
</div>

  <hr>
  <a href="http://www.consultant.ru/document/cons_doc_LAW_61801/" target="_blank">
    Публикация персональных данных, в том числе фотографий, производится в соответствии с Федеральным законом РФ № 152-ФЗ "О персональных данных" от 27.07.2006 г.
  </a>
</div>