<script>
  import { fade } from "svelte/transition";
  import { onMount, onDestroy } from "svelte";

  let fotos = [
    "1.jpg",
    "Слоган 1.jpg",
    "2.jpg",
    "Слоган 2.jpg",
    "3.jpg",
    "Слоган 3.jpg"
  ];
  let foto = [];
  let count = -1;
  let time = 3000;
  let globalInterval;

  onMount(() => {
    globalInterval = setInterval(() => {
      if (count == fotos.length - 1) {
        count = 0;
      } else {
        count++;
      }
      foto = [];
      let timeout = setTimeout(() => {
        foto = [...foto, fotos[count]];
        clearTimeout(timeout);
      }, time);
    }, time * 2);
  });
  onDestroy(() => {
    clearInterval(globalInterval);
  });
</script>

<div class="header-carousel">
  {#each foto as item (item)}
    <div class="header-carousel-box" transition:fade>
      <img class="header-carousel-img" src="/thm/130/{item}" alt="img" />
    </div>
  {/each}
</div>
