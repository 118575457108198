<script>
	import { getContext } from "svelte";
	import base64 from './base64'
	import { onMount } from 'svelte';
	let { wgu } = getContext("sad");
	let scritWgu;
	let jsId;
	
	
	if (wgu.script) {
		scritWgu =  base64.decode(wgu.script);
		let regexp = new RegExp("<SCRIPT>(.|\n)*?<\/SCRIPT>", 'igmsu');
		let jsWgu = scritWgu.match(regexp);
		console.log(jsWgu[1]);

		jsId = jsWgu[1].match(/\d+/);
		console.log(jsId[0]);
	} 
	onMount( async () => {
		Widget("https://pos.gosuslugi.ru/form", jsId);
		
});
</script>
   
<div>
	{#if scritWgu}
		{@html scritWgu}
	{/if}

</div> 
    
    