<script>
  import { fade } from "svelte/transition";
  import { onMount, onDestroy } from "svelte";

  let fotos = [
    "11.jpg",
    "12.jpg",
    "21.jpeg",
    "22.jpg",
    "31.jpg",
    "32.jpg"
  ];
  let foto = [];
  let count = 0;
  let time = 3000;
  let globalInterval;
 onMount(() => {
    globalInterval = setInterval(() => {
      if (count == fotos.length - 1) {
        count = 0;
      } else {
        count++;
      }
      
    }, time);
  }); 

  /*onMount(() => {
    globalInterval = setInterval(() => {
      if (count == fotos.length - 1) {
        count = 0;
      } else {
        count++;
      }
      foto = [];
      let timeout = setTimeout(() => {
        foto = [...foto, fotos[count]];
        console.log(foto);
        clearTimeout(timeout);
      }, time);
    }, time * 2);
  }); */
  onDestroy(() => {
    clearInterval(globalInterval);
  });
</script>

<div class="header-carousel">
  
    <div class="header-carousel-box" >
      <img class="header-carousel-img" src="/thm/272/{fotos[count]}" alt="img" transition:fade/>
    </div>
  
</div>
