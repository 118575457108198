<script>
export let sad;

</script>
<div class="header">
  <h1>
    {sad.name} 
  </h1>
  {#if sad.id != 918}
  <h2>

    {sad.full_name} 
  </h2>
  {/if}
  <div class="hidden-number">{sad.nomer} </div>
</div>