<script>
  import { imgCounter, albumSlide } from "../routes/album/album-store.js";
  import { createEventDispatcher } from "svelte";
  import { fly, fade } from "svelte/transition";
  import { quadInOut } from "svelte/easing";
  let album = $albumSlide;
  let fotos = [];
  let foto = [];
  let count = $imgCounter;
  let x = 300;
  const dispatch = createEventDispatcher();

  album.map((item, index) => {
    fotos[index] = item.path;
  });
  foto = [...foto, fotos[count]];

  const countInc = () => {
    console.log('inc '+count);
    x = 300;
    if (count == fotos.length - 1) {
      count = 0;
    } else {
      count++;
    }
    foto = [];
    let timeout = setTimeout(() => {
      foto = [...foto, fotos[count]];
      clearTimeout(timeout);
    }, 500);
  };

  const countDec = () => {
    console.log('dec '+count)
    x = -300;
    if (count == 0) {
      count = fotos.length - 1;
    } else {
      count--;
    }
    foto = [];
    let timeout = setTimeout(() => {
      foto = [...foto, fotos[count]];
      clearTimeout(timeout);
    }, 500);
  };
  const closeHandler = () => {
    dispatch("closeslider");
  };
</script>

<style>
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    z-index: 100;
  }
  .foto-slider {
    padding: 0;
    display: table;
    width: 60%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    z-index: 200;
    max-width: 90vh;
  }

  .foto-slider img {
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: 90vh;
    max-height: 98vh;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 1);
  }
  .foto-slider span {
    font-size: 100px;
    color: #fff;
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    visibility: hidden;
    transition: visibility 0.3s;
    z-index: 201;
  }
  .foto-slider:hover span {
    visibility: visible;
  }
  .foto-slider span:hover {
    color: rgb(53, 153, 187);
  }
  .foto-slider .left {
    left: 2%;
  }
  .foto-slider .right {
    right: 2%;
  }

  @media (max-width: 950px) {
    .foto-slider {
      width: 70%;
    }
  }
  @media (max-width: 850px) {
    .foto-slider {
      width: 80%;
    }
    .foto-slider span {
      font-size: 80px;
    }
    .foto-slider .left {
      left: 5%;
    }
    .foto-slider .right {
      right: 5%;
    }
  }
</style>

<div class="backdrop" on:click={closeHandler} transition:fade />
{#each foto as item (item)}
  <div
    in:fly={{ duration: 500, x: -x, opacity: 0, easing: quadInOut }}
    out:fly={{ duration: 500, x: x, opacity: 0, easing: quadInOut }}
    class="foto-slider">
    <span class="left" on:click={countDec}>&laquo;</span>
    <img src={item.replace(/http:\/\/mdoy.pro/g, 'https://mdoy.pro')} alt="img" />
    <span class="right" on:click={countInc}>&raquo;</span>
  </div>
{/each}
