
<script>
   // import { onMount } from 'svelte';
  //export let post;
  import MenuItem from './MenuItem.svelte'
  
  export let data = {name: 'not'};
</script>

<div class="menu">
  <h3>
    Меню
  </h3>

  {#if data.in}
    <MenuItem items={data.in}/>
  {/if}
</div>

