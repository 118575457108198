<script context="module">
  import fetch_param from "./_fetch";
  export async function preload({ host, path, params, query }) {
    //console.log(`host ${host}`);
    fetch_param.headers.host = host;
    const res = await this.fetch(`/api/sad.json`, fetch_param);
    let sad = await res.json();
    //console.log(sad);
    if (res.status === 200) {
      if (sad.id) {
        const res1 = await this.fetch(`/api/menu/${sad.id}.json`, fetch_param);
        if (res1.status === 200) {
          sad.menu = await res1.json();
        } else {
          this.error(res.status);
        }
				const res2 = await this.fetch(`/api/wgu/${sad.id}.json`, fetch_param);
        if (res2.status === 200) {
          sad.wgu = await res2.json();
        } else {
          this.error(res.status);
        }
      }
      sad.name = sad.name.replace(/&quot;/g, '"');
      sad.full_name = sad.full_name.replace(/&quot;/g, '"');
      return { sad };
    } else {
      //console.log(`ddddds ${res.status}`);
      this.error(res.status, "Страница не найдена");
      //this.error(404, 'Страница не найдена');
      //return { sad };
    }
  }
</script>

<script>
  //import Nav from '../components/Nav.svelte';
  import Menu from "../components/Menu.svelte";
  import Str from "../components/Str.svelte";
  import ContactItem from "../components/ContactItem.svelte";
  import Header from "../components/Header.svelte";
  import LogoHeader from "../components/LogoHeader.svelte";
  import HorMenu from "../components/HorMenu.svelte";
  import Pos from "../components/Pos.svelte";
  import HorMenu1 from "../components/HorMenu1.svelte";
  import Info from "../components/Info.svelte";
  import Info1 from "../components/Info1.svelte";
  import Carousel from "../components/Carousel.svelte";
  import Carousel1 from "../components/Carousel1.svelte";
  import FotoSlider from "../components/FotoSlider.svelte";
  import { setContext } from "svelte";
  import { showSlider } from "../routes/album/album-store.js";

  export let sad;//
  //export let segment;
  setContext("sad", sad);
  //  console.log(sad);

  let vid = false;
  
  function chvid() {
    vid = !vid;
    //console.log(vid);
  }

  let hovering;
  function enter() {
    hovering = true;
  }
  function leave() {
    hovering = false;
  }
  $: vidstr = `${vid ? "отключить" : "включить"} версию для слабовидящих`;
  $: vidimg = `${
    vid ? "baseline-visibility_off-24px" : "baseline-visibility-24px"
  }.svg`;
  function scrolltop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
  const close = () => {
    showSlider.update(item => (item = false));
  };

//  sad.theme_id = 130;
</script>

<!--<script>
/*	import Nav from '../components/Nav.svelte';
	import Api from '@beyonk/sapper-httpclient';
	import { onMount } from 'svelte'

	export let segment;

	onMount(async () => {
		// await Api
		// 	.create()
		// 	.endpoint('https://example.com')
		// 	.get(() => {
		// 		console.log('got it')
		// 	})
	}) */
</script>-->
<!--<style>
	main {
		position: relative;
		max-width: 56em;
		background-color: white;
		display: block;
		padding: 2em;
		margin: 0 auto;
		box-sizing: border-box;
	}
</style><Nav {segment}/>-->
<svelte:head>
  <title>{sad.name.replace(/&quot;/g, '"')}</title>
  {#if vid}
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/variables_visually-impaired_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/visually-impaired_1.scss";
    </style>
  {:else if sad.nomer == 9}
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/chess9/variables_chess9_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/chess9/main.scss";
    </style>
  {:else if sad.nomer == 272}
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/272/variables_130_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/272/main.scss";
    </style>
  {:else if sad.theme_id == 1}
    <!-- <link rel="stylesheet" href="/thm/Летняя/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Летняя/variables_summer_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Летняя/main.scss";
    </style>
  {:else if sad.theme_id == 2}
    <!-- <link rel="stylesheet" href="/thm/Стандартная/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Стандартная/variables_standart_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Стандартная/main.scss";
    </style>
  {:else if sad.theme_id == 3}
    <!-- <link rel="stylesheet" href="/thm/Гламурная/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Гламурная/variables_glam_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Гламурная/main.scss";
    </style>
  {:else if sad.theme_id == 4}
    <!-- <link rel="stylesheet" href="/thm/Морская/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Морская/variables_sea_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Морская/main.scss";
    </style>
  {:else if sad.theme_id == 5}
    <!-- <link rel="stylesheet" href="/thm/Сказочная/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Сказочная/variables_fairy_tale_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Сказочная/main.scss";
    </style>
  {:else if sad.theme_id == 6}
    <!-- <link rel="stylesheet" href="/thm/Природная/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Природная/variables_nature_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Природная/main.scss";
    </style>
  {:else if sad.theme_id == 7}
    <!-- <link rel="stylesheet" href="/thm/Зеленая/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Зеленая/variables_green_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Зеленая/main.scss";
    </style>
  {:else if sad.theme_id == 8}
    <!-- <link rel="stylesheet" href="/thm/Солнечная-орг/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Солнечная-орг/variables_sun_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Солнечная-орг/main.scss";
    </style>
  {:else if sad.theme_id == 9}
     <link rel="stylesheet" href="/thm/Воздушная/main.css" />
    <!--<style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Морская_пед/variables_sea_ped_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Морская_пед/main.scss";
    </style> -->

  {:else if sad.theme_id == 104}
    <!-- <link rel="stylesheet" href="/thm/Морская_пед/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Морская_пед/variables_sea_ped_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Морская_пед/main.scss";
    </style>

  {:else if sad.theme_id == 10}
     <!-- <link rel="stylesheet" href="/thm/Радужная/main.css" /> -->
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/Радужная/variables_rainbow_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/Радужная/main.scss";
 
    </style>

  {:else if sad.theme_id == 130}
    <style lang="scss">
      @import "./static/variables.scss";
      @import "./static/thm/130/variables_130_thm.scss";
      @import "./static/global_1.scss";
      @import "./static/thm/130/main.scss";
    </style>
  
  {/if}

</svelte:head>

{#if sad.error}
  {sad.message}
{:else}
  <div class="cont-grid">
    <div class="visually" on:click={chvid} data-title={vidstr}>
      <img src={vidimg} alt={vidstr} />
    </div>
    {#if sad.nomer == 272}
    <div class="contact-grid">
      <Info1 {sad} />
    </div>
    {:else}
    <div class="contact-grid">
      <Info {sad} />
    </div>
    {/if}

    {#if sad.logo != ''}
      <div class="header-grid">
        {#if !vid && sad.theme_id == 130}
          <Carousel />
          <LogoHeader {sad} />
        {:else if !vid && sad.nomer == 272}
        <Carousel1 />
        
        {:else}
          <LogoHeader {sad} />
        {/if}
      </div>
    {:else}
      <div class="header-grid">
        {#if !vid && sad.theme_id == 130}
          <Carousel />
          <Header {sad} />
        {:else if !vid && sad.nomer == 272}
          <Carousel1 />
          <Header {sad} />
        {:else}
          <Header {sad} />
        {/if}
      </div>
    {/if}
    
    <div class="hor-menu-grid">
      {#if sad.nomer == 272}
      <HorMenu1 />
      {:else}
      <HorMenu />
      {/if}
      
    </div>
        
    <section class="content">
      <div class="menu-grid">
        
        <Menu data={sad.menu} />
        {#if !vid}
          <a
            class="gossuslugi"
            href="https://www.gosuslugi.ru/"
            target="_blank">
            <img
              src="250x250(alfa).png"
              width="100%"
              alt="ссылка на госуслуги" />
          </a>
        {:else}
          <a href="https://www.gosuslugi.ru/" target="_blank">
            <img
              class="gossuslugi-vid"
              src="250x250.png"
              width="100%"
              alt="ссылка на госуслуги" />
          </a>
        {/if}
      </div>

      <main>
        <slot />
      </main>

    </section>
    
		
		

    <div class="footer-grid">
		
      <div class="footer-links">
        <h4>Полезные ссылки:</h4>
        <div class="footer-links-cont">
          <a
            href="https://admgor.nnov.ru/"
            target="_blank"
            title="Официальный сайт администрации Нижнего Новгорода">
            <img src="adm_nn.png" alt="лого" />
          </a>
          <a
            href="https://www.gosuslugi.ru/"
            target="_blank"
            title="Единый портал государственных услуг">
            <img src="gosuslugi.png" alt="лого" />
          </a>
          <a
            href="https://bus.gov.ru/pub/home"
            target="_blank"
            title="Официальный сайт для размещения информации о государственных
            (муниципальных) учреждениях">
            <img src="bus-gov-ru.png" alt="лого" />
          </a>
          <a
            href="https://minobrnauki.gov.ru/"
            target="_blank"
            title="Министерство науки и высшего образования РФ">
            <img src="min-obr.png" alt="лого" />
          </a>
          <a
            href="https://edu.gov.ru/"
            target="_blank"
            title="Минпросвещения России">
            <img src="min_prosvet.png" alt="лого" />
          </a>
          <a
            href="http://obrnadzor.gov.ru/ru/"
            target="_blank"
            title="Федеральныя служба по надзору в сфере образования и науки">
            <img src="rosobrnadzor.png" alt="лого" />
          </a>
        </div>
      </div>
      <div class="footer-mdoy-serv">
        Сайт разработан компанией
        <a href="https://mdoy.pro" target="_blank">МДОУ-сервис</a>
        <br />
        <p class="footer-dop">
          Адрес: {sad.adres}
          <br />
          Телефон:
          <a href="tel:{sad.phone}">{sad.phone}</a>
          <br />
          Email:
          <a href="mailto:{sad.email}">{sad.email}</a>
        </p>
      </div>

    </div>
    {#if $showSlider}
      <FotoSlider on:closeslider={close} />
    {/if}
  </div>
<!--
  <div class="pers-acc-circle">
    <img src="account_circle-24px.svg" alt="Личный кабинет" />
    <ul>
      <li>
        <a href=" ">Вход</a>
      </li>
      <li>
        <a href=" ">Личный кабинет</a>
      </li>
    </ul>
  </div>
-->
  <div class="arrow-up-circle" data-title="Вверх" on:click={scrolltop}>
    <img src="baseline-arrow_upward-24px.svg" alt="прокрутка вверх" />
  </div>
{/if}

<!-- для всей страницы -->
