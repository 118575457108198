<script>
  export let items;
</script>

<ul>
  {#each items as item}
    <li>
      
      {#if item.in && item.in.length >0}
        <a href="#">{item.name}</a>
        <div class="arrow-right"></div>
        <svelte:self items={item.in}/>
      {:else}
      <a href="/?str={item.str}">{item.name}</a>
      {/if}

    </li>
  {/each}
</ul>